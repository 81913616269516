<template>
  <!-- <v-app> -->

 <!-- <v-app>  -->
 <div >
   
     <v-app > 
      <breaking-news />

 <head_header v-if="$route.name=='alkafeel'" />
 <sec-header v-else-if="$route.name!=='muharram' && $route.name!=='host_form' && $route.name!=='host'"></sec-header>

<div>
    <router-view />
</div>


 <footers v-if="$route.name!=='muharram' && $route.name!=='host_form' && $route.name!=='host' && $route.name!=='message'"/>
  </v-app>
 </div>

 <!-- </v-app> -->
</template>



<script>
  export default {
    name: "DashboardIndex",
    components: {
      head_header: () => import("../../components/core/Head.vue"),
      footers: () => import("../../components/core/foot.vue"),
      secHeader: () => import("./Header.vue"),
      //  footers: () => import("../../components/core/footer.vue"),
      // DashboardCoreView: () => import("../../components/core/View")
    },

    data: () => ({
      expandOnHover: false
    })
  };
</script>


<style >

  
</style>